tui-calendar.tag-taiga-ui {

  padding: 0;

  .t-cell_today {
    text-decoration: unset !important;
    &:not([data-range="start"], [data-range="end"], [data-range="active"]) {
      color: var(--tui-text-action) !important;
      &[data-range="middle"] {
        background-color: var(--tui-background-neutral-1) !important;
      }
      &:before {
        border-radius: var(--tui-radius-m) !important;
        border: 1px solid var(--tui-background-accent-1) !important;
        background-color: rgba(41, 121, 255, 0.15) !important;
      }
    }
  }

  [data-type=weekend]:not(.t-cell_today, [data-range="start"], [data-range="end"], [data-range="active"]) {
    color: var(--tui-text-primary) !important;
  }

}

tui-calendar-range.tag-taiga-ui {

  padding: 0;
  min-inline-size: 31rem !important;

  &.mobile {
    min-inline-size: unset !important;

    .t-calendar {
      border-inline-end: unset !important;
    }

    tui-data-list, tui-calendar:last-child {
      display: none !important;
    }

  }

  .t-cell:not([data-range="active"]) {
    &::after {
      border-radius: var(--tui-radius-s) !important;
      mask: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 32"><path d="M0.2856 0L0.6763 0C2.9265 0 4.9876 1.259 6.0147 3.2611L10.2442 11.5048C11.5301 14.0113 11.5683 16.9754 10.3472 19.5141L5.9766 28.6007C4.9772 30.6786 2.8754 32 0.5696 32H0.285645V0Z"></path></svg>') right / .75rem 100% no-repeat, linear-gradient(#000, #000) left / calc(100% - 0.5rem) 100% no-repeat !important;
    }
  }

  .t-cell_today {
    text-decoration: unset !important;
    &:not([data-range="start"], [data-range="end"], [data-range="active"]) {
      color: var(--tui-text-action) !important;
      &[data-range="middle"] {
        background-color: var(--tui-background-neutral-1) !important;
      }
      &:before {
        border-radius: var(--tui-radius-m) !important;
        border: 1px solid var(--tui-background-accent-1) !important;
        background-color: rgba(41, 121, 255, 0.15) !important;
      }
    }
  }

  [data-type=weekend]:not(.t-cell_today, [data-range="start"], [data-range="end"], [data-range="active"]) {
    color: var(--tui-text-primary) !important;
  }

  tui-data-list [tuiOption]:hover {
    color: var(--tui-text-action-hover) !important;
  }

  tui-data-list [tuiOption]:focus-within {
    background: unset !important;
  }
}