@import '@taiga-ui/core/styles/theme/variables';
@import '@taiga-ui/core/styles/theme/palette';

// Override variabili per tema;
@font-fallback: "Roboto","Segoe UI","GeezaPro","DejaVu Serif","sans-serif","-apple-system","BlinkMacSystemFont";

&:root {
  --tui-background-accent-1: #2979ff;
  --tui-background-accent-1-hover: #2367d9;
  --tui-background-accent-1-pressed: #1d55b3;
  --tui-text-action: #2979ff;
  --tui-text-action-hover: #2367d9;
  /* palette colori slate tailwind */
  --tui-background-neutral-1: #f1f5f9; /* 100 */
  --tui-background-neutral-1-hover: #e2e8f0; /* 200 */
  --tui-background-neutral-1-pressed: #cbd5e1; /* 300 */
}

@import '@taiga-ui/core/styles/theme/appearance';
@import '@taiga-ui/core/styles/theme/wrapper';
@import '@taiga-ui/core/styles/theme/appearance/textfield';

// Custom css
@import './components/calendar-range';
